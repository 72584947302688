"use strict";

import axios from "axios";
var baseControllerAddress = 'test/';

export default {

    openGate: (id) => {
        return axios.get('/api/' + baseControllerAddress + "openGate/" + id)
            .then(result => {
                return result.data;
            }).catch(function (error) {
                alert(error);
            });
    },

    closeGate: (id) => {
        return axios.get('/api/' + baseControllerAddress + "closeGate/" + id)
            .then(result => {
                return result.data;
            }).catch(function (error) {
                alert(error);
            });
    },

    toggleSignal: (model) => {
        return axios.post('/api/' + baseControllerAddress + "toggleSignal/", model)
            .then(result => {
                return result.data;
            }).catch(function (error) {
                alert(error);
            });
    },
}