"use strict";

import axios from "axios";
var baseControllerAddress = 'gateLog/';
// GateLog
// gateLog
export default {
    getGateLogList: (vm) => {
        return axios.post('/api/' + baseControllerAddress + 'filterGateLogList', vm)
            .then(result => {
                return result.data;
            }).catch(function (error) {
                    if (error.response.status != 401) {
                            alert(error);
                    }
            });
    },
    getGateLog: (id) => {
        return axios.get('/api/' + baseControllerAddress + "get/" + id)
            .then(result => {
                return result.data;
            }).catch(function (error) {
                alert(error);
            });
    },
    getTypes: () => {
        return axios.get('/api/' + baseControllerAddress + "getTypes")
            .then(result => {
                return result.data;
            }).catch(function (error) {
                alert(error);
            });
    }
   
   
    
}