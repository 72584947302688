var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',{staticClass:"mb-2",attrs:{"align-v":"center"}},[_c('b-col',[_vm._v(" "+_vm._s(_vm.pagination.totalListCount)+" schedules found ")]),_c('b-col',[_c('b-input',{attrs:{"placeholder":"Search schedules"},model:{value:(_vm.searchField),callback:function ($$v) {_vm.searchField=$$v},expression:"searchField"}})],1),_c('b-col',{staticClass:"d-flex align-items-end justify-content-end"},[_c('b-button',{attrs:{"variant":"success"},on:{"click":_vm.createSchedule}},[_c('b-icon',{staticClass:"mr-2",attrs:{"icon":"plus"}}),_vm._v(" Create schedule ")],1)],1)],1),_c('div',{staticClass:"d-flex align-items-center mb-1"},[(_vm.gateSchedulesSelected.length)?_c('b-link',{staticClass:"mr-3",staticStyle:{"font-size":".8em"},on:{"click":function($event){return _vm.deleteSelectedSchedules()}}},[_c('span',{staticStyle:{"color":"#c00 !important"}},[_c('b-icon',{attrs:{"icon":"x"}}),_vm._v("Delete selected schedules")],1)]):_vm._e()],1),(_vm.gateScheduleList.length)?_c('div',[_c('b-table',{ref:"schedulesTable",attrs:{"id":"gate-schedules-table","thead-class":"dmx-thead-block","outlined":"","striped":"","bordered":"","busy":_vm.loading,"items":_vm.gateScheduleList,"fields":_vm.headers},on:{"row-clicked":_vm.editSchedule},scopedSlots:_vm._u([{key:"cell(name)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-content-start align-items-center"},[_c('b-form-checkbox',{attrs:{"value":item.id},model:{value:(_vm.gateSchedulesSelected),callback:function ($$v) {_vm.gateSchedulesSelected=$$v},expression:"gateSchedulesSelected"}}),_vm._v(" "+_vm._s(item.name)+" ")],1)]}},{key:"cell(enabled)",fn:function(ref){
var item = ref.item;
return [(item.enabled)?_c('label',{staticClass:"badge badge-success",staticStyle:{"cursor":"pointer"},on:{"click":function($event){$event.stopPropagation();return _vm.toggleSchedule(item)}}},[_vm._v("Active")]):_c('label',{staticClass:"badge badge-secondary",staticStyle:{"cursor":"pointer"},on:{"click":function($event){$event.stopPropagation();return _vm.toggleSchedule(item)}}},[_vm._v("Inactive")])]}},{key:"cell(selected)",fn:function(ref){
var item = ref.item;
return [(item.selected)?_c('label',{staticClass:"badge badge-success",staticStyle:{"cursor":"pointer"},on:{"click":function($event){$event.stopPropagation();return _vm.setAsSelected(item)}}},[_vm._v("Selected")]):_c('label',{staticClass:"badge badge-secondary",staticStyle:{"cursor":"pointer"},on:{"click":function($event){$event.stopPropagation();return _vm.setAsSelected(item)}}},[_vm._v("Not selected")])]}},{key:"cell(modifiedOnUtc)",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.utcFormatted(value))+" ")]}}],null,false,1988886287)}),_c('b-pagination',{attrs:{"align":"center","total-rows":_vm.pagination.totalListCount,"per-page":_vm.options.itemsPerPage,"aria-controls":"gates-log-table"},model:{value:(_vm.options.page),callback:function ($$v) {_vm.$set(_vm.options, "page", $$v)},expression:"options.page"}})],1):_c('div',{attrs:{"align":"center"}},[_c('i',[_vm._v("No Schedules found for this gate. ")])]),_c('b-modal',{ref:"schedule-modal",attrs:{"center":"","size":"lg","id":"schedule-modal","hide-footer":""},scopedSlots:_vm._u([(_vm.selectedSchedule.id > 0)?{key:"modal-title",fn:function(){return [_vm._v(" Editing "+_vm._s(_vm.selectedSchedule.name || '')+" ")]},proxy:true}:{key:"modal-title",fn:function(){return [_vm._v(" Create new schedule ")]},proxy:true}],null,true)},[_c('gate-schedule',{attrs:{"existingschedule":_vm.selectedSchedule,"gateId":_vm.gate.id}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }