"use strict";

import axios from "axios";
var baseControllerAddress = 'gateInvite/';
// GateInvite
// gateInvite
export default {
    getGateInviteList: (vm) => {
        return axios.post('/api/' + baseControllerAddress + 'filterGateInviteList', vm)
            .then(result => {
                return result.data;
            }).catch(function (error) {
                    if (error.response.status != 401) {
                            alert(error);
                    }
            });
    },
    
    createGateInvite: (vm) => {
        return axios.post('/api/' + baseControllerAddress + "create", vm)
            .catch(function (error) {
                alert(error);
            });
    },
    getGateInvite: (id) => {
        return axios.get('/api/' + baseControllerAddress + "get/" + id)
            .then(result => {
                return result.data;
            }).catch(function (error) {
                alert(error);
            });
    },
    renewGateInvite: (vm) => {
        return axios.put('/api/' + baseControllerAddress + "renew", vm)
            .then(result => {
                return result.data;
            }).catch(function (error) {
                alert(error);
            });
    },
    sendGateInvite: (id) => {
        return axios.get('/api/' + baseControllerAddress + "send/" + id)
            .then(result => {
                return result.data;
            }).catch(function (error) {
                alert(error);
            });
    },
    deleteAll: () => {
        return axios.delete('/api/' + baseControllerAddress + "deleteAllGateInvites")
            .catch(function (error) {
                alert(error);
            });
    },
    deleteGateInvite: (id) => {
        return axios.delete('/api/' + baseControllerAddress + "deleteGateInvite/" + id)
            .catch(function (error) {
                alert(error);
            });
    }
}