<template>
    <div>
        <b-row class="mb-2">
            <b-col>
                <b-row>
                    <b-col>
                        <b>From date</b>
                        <b-form-datepicker id="from-date" v-model="options.fromDate"></b-form-datepicker>
                    </b-col>
                    <b-col>
                        <b>From time</b>
                        <b-form-timepicker id="from-time" v-model="options.fromTime"></b-form-timepicker>
                    </b-col>
                </b-row>
            </b-col>
            <b-col class="ml-1">
                <b-row>
                    <b-col>
                        <b>To date</b>
                        <b-form-datepicker id="to-date" v-model="options.toDate"></b-form-datepicker>
                    </b-col>
                    <b-col>
                        <b>To time</b>
                        <b-form-timepicker id="to-time" v-model="options.toTime"></b-form-timepicker>
                    </b-col>
                </b-row>
            </b-col>
            <b-col>
                <b>Search</b> <b-input v-model="searchField" placeholder="Search log entries"></b-input>
            </b-col>
            <b-col style="align-content: end;" class="col-1">
                <button class="btn btn-light btn-block" @click="refreshList" ><span style="font-weight: bold;font-size:50px;">🗘</span></button>
            </b-col>
        </b-row>
        <b-table id="gates-log-table" thead-class="dmx-thead-block" outlined striped bordered :busy="loading"
            :items="gateLogList" :fields="headers">
        </b-table>
        <b-pagination align="center" v-model="options.page" :total-rows="pagination.totalListCount"
            :per-page="options.itemsPerPage" aria-controls="gates-log-table">
        </b-pagination>
    </div>
</template>

<style scoped></style>

<script>
import gateLogService from '@/services/gateLogService';
import moment from 'moment';
import i18n from '@/i18n';

export default {
    props: {
        gate: {},
    },
    data() {
        return {
            loading: true,
            searchField: '',
            searchFieldIsLoading: false,
            searchDebouncer: 0,
            pageDebouncer: 0,
            typeList: [],
            gateLogList: [],
            options: {
                type: 0,
                fromDate: null,
                toDate: null,
                fromTime: null,
                toTime: null,
                page: 1,
                itemsPerPage: 10,
            },
            pagination: {
                totalListCount: 0
            },
        }
    },
    methods: {
        getTypes() {
            gateLogService.getTypes().then((data) => {
                this.typeList = data;
                this.loading = false;
            }).catch(function (error) {
                alert(error);
            });
        },
        editGateLog(e) {
            // router.push({ name: 'gateLogEdit', params: { gateLogId: parseInt(e.id) } })
        },
        refreshList(){
            this.options = {
                type: 0,
                fromDate: null,
                toDate: null,
                fromTime: null,
                toTime: null,
                page: 1,
                itemsPerPage: 10,
            };
            this.getGateLogs();
        },
        getGateLogs() {
            let reqOb = {
                Id: this.gate.id,
                Count: this.options.itemsPerPage,
                Gates: [],
                SortBy: 'createdOn',
                SearchTerm: this.searchField,
                SortDesc: true,
                Page: this.options.page,
                Type: this.options.type,
                FromDate: this.options.fromDate,
                ToDate: this.options.toDate,
                FromTime: this.options.fromTime,
                ToTime: this.options.toTime
            };

            gateLogService.getGateLogList(reqOb)
                .then((data) => {
                    if (data.items) {
                        data.items.map(x => {
                            x.createdOn = moment(x.createdOn).format('YYYY-MM-DD HH:mm:ss');
                            x.title = i18n.t(x.title);
                            x.text = i18n.t(x.text);
                            x.message = i18n.t(x.message);
                        });
                        this.gateLogList = data.items;
                        this.searchFieldIsLoading = false;
                        this.options.page = data.page;
                        this.pagination.totalListCount = data.totalListCount;
                    }
                    this.loading = false;
                })
                .catch(function (error) {
                    alert(error);
                });
        },

    },
    computed: {
        headers() {
            return [
                {
                    label: 'Created',
                    sortable: true,
                    key: 'createdOn'
                },
                {
                    label: 'Type',
                    sortable: true,
                    key: 'type'
                },
                {
                    label: 'Icon',
                    sortable: true,
                    key: 'icon'
                },
                {
                    label: 'Title',
                    sortable: true,
                    key: 'title'
                },
                {
                    label: 'Text icon',
                    sortable: true,
                    key: 'textIcon',
                },
                {
                    label: 'Text',
                    sortable: true,
                    key: 'text'
                },
                {
                    label: 'Message',
                    sortable: true,
                    key: 'message'
                }
            ]
        },
    },

    watch: {
        options: {
            handler() {
                const self = this;
                clearTimeout(this.pageDebouncer);
                this.pageDebouncer = setTimeout(() => {
                    self.getGateLogs()
                }, 400);
            },
            deep: true,
            immediate: true,
        },
        searchField: {
            handler() {
                if (this.searchField.length == 0 || this.searchField.length > 1) {
                    const self = this;
                    clearTimeout(this.searchDebouncer);
                    this.searchDebouncer = setTimeout(() => {
                        self.getGateLogs()
                    }, 400);
                }
            }
        }
    }
}
</script>