var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',{staticClass:"mb-2",attrs:{"align-v":"center"}},[_c('b-col',[_vm._v(" "+_vm._s(_vm.pagination.totalListCount)+" invites found ")]),_c('b-col',[_c('b-input',{attrs:{"placeholder":"Search gate invites"},model:{value:(_vm.searchField),callback:function ($$v) {_vm.searchField=$$v},expression:"searchField"}})],1),_c('b-col',{staticClass:"d-flex align-items-end justify-content-end"},[_c('b-button',{directives:[{name:"b-modal",rawName:"v-b-modal.invitation-modal",modifiers:{"invitation-modal":true}}],attrs:{"variant":"success"}},[_c('b-icon',{staticClass:"mr-2",attrs:{"icon":"plus"}}),_vm._v(" Create invite ")],1)],1)],1),(_vm.gateInvitesSelected.length)?_c('div',{staticClass:"d-flex align-items-center mb-1",staticStyle:{"font-size":".9em"}},[_c('b-link',{staticClass:"mr-3",on:{"click":function($event){return _vm.revokeGateInvites()}}},[_c('span',{staticStyle:{"color":"#c00 !important"}},[_c('b-icon',{attrs:{"icon":"x"}}),_vm._v(" Revoke selected ")],1)]),_c('b-link',{staticClass:"mr-3",on:{"click":function($event){return _vm.renewGateInvites()}}},[_c('span',[_c('b-icon',{attrs:{"icon":"arrow-clockwise"}}),_vm._v(" Renew selected ")],1)]),_c('b-link',{staticClass:"mr-3",on:{"click":function($event){return _vm.resendGateInvites()}}},[_c('span',[_c('b-icon',{attrs:{"icon":"box-arrow-up-right"}}),_vm._v(" Resend selected ")],1)])],1):_vm._e(),(_vm.gateInvitesList.length)?_c('div',[_c('b-table',{attrs:{"id":"gate-invites-table","thead-class":"dmx-thead-block","outlined":"","striped":"","bordered":"","hover":"","selectable":"","busy":_vm.loading,"items":_vm.gateInvitesList,"fields":_vm.headers},on:{"row-selected":_vm.onRowSelected},scopedSlots:_vm._u([{key:"cell(email)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-content-start align-items-center"},[_c('b-form-checkbox',{attrs:{"value":item.id},model:{value:(_vm.gateInvitesSelected),callback:function ($$v) {_vm.gateInvitesSelected=$$v},expression:"gateInvitesSelected"}}),_vm._v(" "+_vm._s(item.email)+" ")],1)]}},{key:"cell(createdOnUtc)",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.utcFormatted(value))+" ")]}},{key:"cell(validToUtc)",fn:function(ref){
var item = ref.item;
var value = ref.value;
return [_c('b-link',{attrs:{"title":"Click to renew invite"},on:{"click":function($event){return _vm.renewGateInvites(item.id)}}},[_vm._v(" "+_vm._s(_vm.utcFormatted(value))+" "),_c('b-icon',{staticClass:"ml-2",attrs:{"icon":"arrow-clockwise"}})],1)]}},{key:"cell(sent)",fn:function(ref){
var item = ref.item;
var value = ref.value;
return [_c('b-link',{staticClass:"d-flex justify-content-center align-items-center",attrs:{"title":"Click to resend invitation"},on:{"click":function($event){return _vm.resendGateInvites(item.id)}}},[(value)?_c('b-icon',{attrs:{"icon":"check","variant":"success","font-scale":"1.5"}}):_c('b-icon',{attrs:{"icon":"x","variant":"danger","font-scale":"1.5"}}),_c('b-icon',{staticClass:"ml-2",attrs:{"icon":"box-arrow-up-right"}})],1)]}}],null,false,1375285787)}),_c('b-pagination',{attrs:{"align":"center","total-rows":_vm.pagination.totalListCount,"per-page":_vm.options.itemsPerPage,"aria-controls":"gate-invites-table"},model:{value:(_vm.options.page),callback:function ($$v) {_vm.$set(_vm.options, "page", $$v)},expression:"options.page"}})],1):_c('div',{attrs:{"align":"center"}},[_c('b-link',{directives:[{name:"b-modal",rawName:"v-b-modal.invitation-modal",modifiers:{"invitation-modal":true}}]},[_vm._v("No invites found, click to create")]),_vm._v(". ")],1),_c('b-modal',{ref:"invitation-modal",attrs:{"center":"","size":"md","id":"invitation-modal","hide-footer":""},scopedSlots:_vm._u([{key:"modal-title",fn:function(){return [_c('h5',[_vm._v("Create invitation to "),_c('b',[_vm._v(_vm._s(_vm.gate.name))])])]},proxy:true}])},[_c('gate-user-invitation',{attrs:{"gate":_vm.gate}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }