<template>
    <div>
        <b-row align-v="center">
            <b-col sm="7">
                Name
                <b-input v-model="schedule.scheduleName"></b-input>
            </b-col>
            <b-col sm="5">
                <b-form-checkbox v-model="schedule.enabled" class="mt-4">
                    <b>Schema selected and enabled</b>
                </b-form-checkbox>
            </b-col>
        </b-row>
        <hr />
        <b-row>
            <b-col>
                <small>Start</small>
                <b-form-timepicker hide-header size="sm" v-model="timeStart"></b-form-timepicker>
            </b-col>
            <b-col>
                <small>End</small>
                <b-form-timepicker hide-header size="sm" v-model="timeEnd"></b-form-timepicker>
            </b-col>
        </b-row>
        <b-row>
            <b-col class="d-flex">
                <div v-for="day in dayOfWeek" :key="day.id">
                    <b-button v-if="timeStart && timeEnd" class="btn btn-sm btn-info mr-1" v-on:click="setInterval(day)">
                        {{ day.name }}
                    </b-button>
                    <b-button style="cursor:default" v-else class="btn btn-sm btn-secondary mr-1" disabled>
                        {{ day.name }}
                    </b-button>
                </div>
            </b-col>
        </b-row>
        <b class="text-danger" v-if="errorMessage">{{ errorMessage }}</b>
        <hr />
        <div>

        </div>
        <b-row v-if="schedule.intervals" v-for="interval in schedule.intervals"
            :key="interval.dayOfWeek">

            <b-col>
                <b>{{ findDayName(interval.dayOfWeek) }}</b>
                <b> {{ interval.order }} </b>
            </b-col>
            <b-col>
                <small><b>Start: </b></small>
                {{ interval.timeStart }}
            </b-col>
            <b-col>
                <small><b>End: </b></small>
                {{ interval.timeEnd }}
            </b-col>
            <b-col>
                <b-link @click="removeInterval(interval.dayOfWeek)" class="mr-3" style="font-size: .8em;">
                    <span style="color: #c00 !important;"><b-icon icon="x"></b-icon>Remove interval</span>
                </b-link>
            </b-col>
        </b-row>
        <b-row v-if="schedule.intervals.length < 1 && schedule.id == 0">
            <b-col>
                <em>No intervals added</em>
            </b-col>
        </b-row>
        <hr />
        <b-row>
            <b-col>
                <b-form-checkbox v-model="schedule.closedOnHolidays">
                    <b>Closed on national holidays</b>
                </b-form-checkbox>
            </b-col>
            <b-col class="d-flex justify-content-end">
                <b-button class="mr-2" @click="cancel()">Cancel</b-button>
                <b-button v-if="schedule.scheduleName && schedule.intervals.length > 0" variant="success"
                    @click="saveSchedule()">{{ (schedule.id) ? 'Save' : 'Create' }}</b-button>
                <b-button style="cursor:default" v-else disabled variant="secondary" @click="saveSchedule()">{{
                    (schedule.id) ? 'Save' : 'Create' }}</b-button>
            </b-col>
        </b-row>
    </div>
</template>

<style scoped></style>

<script>
import gateScheduleService from '@/services/gateScheduleService';
import { DayOfWeek } from '@/variables/variables';
import moment from 'moment';
import i18n from '@/i18n';

export default {
    props: {
        existingschedule: {},
        gateId: 0
    },
    data() {
        return {
            schedule: {
                id: 0,
                scheduleName: '',
                enabled: false,
                closedOnHolidays: false,
                gateId: 0,
                intervals: []
            },
            loading: true,
            interval: {
                dayOfWeek: -1,
                dayOfWeekName: '',
                timeStart: null,
                timeEnd: null
            },
            timeStart: null,
            timeEnd: null,
            dayOfWeek: DayOfWeek,
            errorMessage: ''
        }
    },
    methods: {
        cancel() {
            this.$root.$emit('bv::hide::modal', 'schedule-modal');
        },
        setInterval(day) {
            this.errorMessage = '';
            // Check if Day exists

            this.interval = {};
            this.interval.dayOfWeek = day.id;
            this.interval.dayOfWeekName = day.name
            this.interval.timeStart = this.timeStart;
            this.interval.timeEnd = this.timeEnd;
            if (this.schedule.intervals.some(x => x.dayOfWeek === this.interval.dayOfWeek)) {
                this.errorMessage = 'You cant add same day twice';
            } 
            else if(this.interval.timeEnd <= this.interval.timeStart){
                this.errorMessage = 'Endtime can not be higher then Starttime';
            }
            else {
                this.schedule.intervals.push(this.interval);
            }
        },
        findDayName(id) {
            if(id !== -1) {
                var obj = this.dayOfWeek.find(x => x.id === id);
            return obj.name;
            }
        },
        removeInterval(day) {
            this.schedule.intervals.splice(this.schedule.intervals.findIndex(d => d.dayOfWeek === day), 1)
        },
        saveSchedule() {
            if (this.schedule.id > 0) { // Edit
                gateScheduleService.updateGateSchedule(this.schedule)
                    .then(res => {
                        this.$root.$emit('dmx::saved::gateSchedule');
                    });
            } else { // Create
                
                gateScheduleService.createGateSchedule(this.schedule)
                    .then(res => {
                        this.$root.$emit('dmx::created::gateSchedule');
                    });
            }                   
        },
    },
    mounted() {
        if(this.existingschedule.id > 0) {
            this.schedule = {
                id: this.existingschedule.id,
                scheduleName: this.existingschedule.name,
                enabled: this.existingschedule.enabled,
                closedOnHolidays: this.existingschedule.closedOnHolidays,
                gateId: this.existingschedule.gateId,
                intervals: this.existingschedule.intervals
            };
        }
        this.schedule.gateId = this.gateId;
    },
}
</script>