<template>
    <gmap-map class="gmap" ref="gmap" map-type-id="hybrid" :center="center" :zoom="zoom">
        <gmap-marker :key="index"
                     v-for="(m, index) in locationMarkers"
                     :position="m.position"
                     :icon="(m.gate.status) ? getIcon(m.gate.status) : getIcon(0)"
                     :title="m.gate.displayName"
                     @click="markerClicked(m.gate.id)"
                     @mouseover="showByIndex = index"
                     @mouseout="showByIndex = null">
            <gmap-info-window :opened="showByIndex === index">
                <p>{{m.gate.displayName}} - {{m.gate.company}}</p>
            </gmap-info-window>
        </gmap-marker>
    </gmap-map>
</template>

<style scoped>
    .gmap {
        width: 100%;
        height: 100%;
    }
</style>

<script>
    export default {
        props: {
            gates: [],
            maxZoom: {
                default: 17,
                type: Number
            },
            minZoom: {
                default: 7,
                type: Number
            },
        },
        data() {
            return {
                locationMarkers: [],
                center: {
                    lat: 57.189531,
                    lng: 14.041255
                },
                zoom: 10,
                showByIndex: null,
            }
        },
        methods: {
            markerClicked(id) {
                this.$emit('marker-clicked', id);
            },
            addLocationMarkers() {
                this.locationMarkers = [];
                this.$refs.gmap.$mapPromise.then((map) => {
                    const bounds = new google.maps.LatLngBounds(null);
                    for (let i in this.gates) {
                        if (this.gates[i].deviceLat && this.gates[i].deviceLon) {
                            const marker = {
                                lat: this.gates[i].deviceLat,
                                lng: this.gates[i].deviceLon,
                            }
                            this.locationMarkers.push({
                                position: marker,
                                gate: this.gates[i],
                            });
                            bounds.extend(marker);
                        }
                    }
                   
                    if (this.locationMarkers.length) {
                        map.fitBounds(bounds);
                        //const self = this;
                        //var listener = google.maps.event.addListener(map, "idle", function () { // wait for fitBounds to finish
                        //    if (map.getZoom() > self.maxZoom) map.setZoom(self.maxZoom);
                        //    if (map.getZoom() < self.minZoom) map.setZoom(self.minZoom);
                        //    google.maps.event.removeListener(listener);
                        //});

                        this.calculateAvgPosition(this.locationMarkers);
                        map.panTo(this.center);
                    } 
                });
            },
            calculateAvgPosition(locationMarkers) {
                var allLat = 0;
                var allLng = 0; 
                for (let i in locationMarkers) {
                    allLat += locationMarkers[i].position.lat;
                    allLng += locationMarkers[i].position.lng;
                }
                const avgLat = allLat / locationMarkers.length;
                const avgLng = allLng / locationMarkers.length;

                this.center = {
                    lat: avgLat,
                    lng: avgLng
                };
            },
            getIcon(state) {
                let icn = {
                    0: { // unknown
                        path: 'M 0 0 L 2 -3 L 5 -3 L 5 -9 L -5 -9 L -5 -3 L -2 -3 L 0 0',
                        fillColor: '#ccc',
                        fillOpacity: 1,
                        strokeColor: '#000',
                        strokeWeight: 1,
                        scale: 2,
                    },      
                    10: { // closed
                        path: 'M 0 0 L 2 -3 L 5 -3 L 5 -9 L -5 -9 L -5 -3 L -2 -3 L 0 0',
                        fillColor: '#F00',
                        fillOpacity: 1,
                        strokeColor: '#000',
                        strokeWeight: 1,
                        scale: 2,
                    },     
                    20: { // open
                        path: 'M 0 0 L 2 -3 L 5 -3 L 5 -9 L -5 -9 L -5 -3 L -2 -3 L 0 0',
                        fillColor: '#0F0',
                        fillOpacity: 1,
                        strokeColor: '#000',
                        strokeWeight: 1,
                        scale: 2,
                    },     
                    30: { // opening
                        path: 'M 0 0 L 2 -3 L 5 -3 L 5 -9 L -5 -9 L -5 -3 L -2 -3 L 0 0',
                        fillColor: '#0F0',
                        fillOpacity: .5,
                        strokeColor: '#000',
                        strokeWeight: 1,
                        scale: 2,
                    },     
                    40: { // closing
                        path: 'M 0 0 L 2 -3 L 5 -3 L 5 -9 L -5 -9 L -5 -3 L -2 -3 L 0 0',
                        fillColor: '#F00',
                        fillOpacity: .5,
                        strokeColor: '#000',
                        strokeWeight: 1,
                        scale: 2,
                    },     
                }
                return icn[state];
            },
            
        },
        computed: {
            
        },
        watch: {
            gates: {
                handler() {
                    this.addLocationMarkers();
                },
            }
        },
        mounted() {
            this.addLocationMarkers();
        },
    }
</script>