"use strict";

import axios from "axios";
var baseControllerAddress = 'connect/';
// Gate
// gate
export default {

    openGate: (id,userActivated) => {
        return axios.get('/api/' + baseControllerAddress + "openGate?id=" + id +"&message=Opened from web GUI" + "&userActivated=" + userActivated)
            .then(result => {
                return result.data;
            }).catch(function (error) {
                alert(error);
            });
    },

    closeGate: (id,userActivated) => {
        return axios.get('/api/' + baseControllerAddress + "setCloseGate?id=" + id + "&userActivated=" + userActivated)
            .then(result => {
                return result.data;
            }).catch(function (error) {
                alert(error);
            });
    },
    activateDetector: (id) => {
        return axios.get('/api/' + baseControllerAddress + "activateDetector/" + id)
            .then(result => {
                return result.data;
            }).catch(function (error) {
                alert(error);
            });
    },
    deactivateDetector: (id) => {
        return axios.get('/api/' + baseControllerAddress + "deactivateDetector/" + id)
            .then(result => {
                return result.data;
            }).catch(function (error) {
                alert(error);
            });
    },
    activateConstantContactControl: (id) => {
        return axios.get('/api/' + baseControllerAddress + "activateConstantContactControl/" + id)
            .then(result => {
                return result.data;
            }).catch(function (error) {
                alert(error);
            });
    },
    deactivateConstantContactControl: (id) => {
        return axios.get('/api/' + baseControllerAddress + "deactivateConstantContactControl/" + id)
            .then(result => {
                return result.data;
            }).catch(function (error) {
                alert(error);
            });
    },
    inviteSingleOrMultipleUsersToGate: (vm) => {
        return axios.post('/api/' + baseControllerAddress + "inviteSingleOrMultipleUsersToGate/", vm)
            .then(result => {
                return result.data;
            }).catch(function (error) {
                alert(error);
            });
    },
}