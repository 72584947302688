

<template>
    <div>
        <b-row class="mt-3 mb-4">
            <b-col sm="12" lg="6" xl="3">
                <div class="sub-box dmx-gate-card selected">
                    <span class="price">Price: 2499kr/år</span>
                    <div class="pt-4">
                        <h4 class="text-center">Basic</h4>
                        <div class="row my-4">
                            <button v-b-modal.modal-downgrade class="btn btn-primary m-auto" type="button">
                                Active
                                <b-icon stacked icon="check" style="width:30px"></b-icon>
                            </button>
                        </div>
                        <div class="p-2">
                            <p class="text-center mb-0">Always included!</p>
                            <b-collapse id="collapse-1">
                                <b-card>
                                    <ul>
                                        <li>LOGGA IN, INBJUDEN, SKAPA NY</li>
                                        <li>ÖPPNA OCH STÄNG GRIND</li>
                                        <li>AUTENSIERINGVIAFACEID</li>
                                        <li>ANTECKNINGVIDÖPPNING</li>
                                        <li>PUSHNOTISER MED VARNINGAR (som bara rör problem med stänging/öppning)</li>
                                        <li>FÖRETAGSKOPPLINGMOTGRIND</li>
                                        <li>ÖVERLÄMNA ÄGANDE</li>
                                        <li>BUGGRAPPORT</li>
                                        <li>INSTÄLLNINGAR FÖR APPEN (VILLKOR OSV)</li>
                                        <li>BJUDINGÄSTANVÄNDrRE</li>
                                        <li>BJUD IN ANVÄNARE</li>
                                        <li>REDIGERA ANVÄNDARE</li>
                                        <li>FAQ</li>
                                        <li>HJÄLP/KONTAKT</li>
                                        <li>HANTERA ABONNEMANG & FAKTURERING</li>
                                    </ul>
                                </b-card>
                            </b-collapse>
                            <button v-b-toggle.collapse-1 variant="primary" class="text-center mb-0 w-100">
                                <div class="icon-box mt-3"><b-icon stacked icon="chevron-down"></b-icon></div>
                            </button>
                        </div>

                    </div>
                </div>
            </b-col>
            <b-col sm="12" lg="6" xl="3">
                <div class="sub-box dmx-gate-card">
                    <span class="price">Price: 3499kr/år</span>
                    <div class="pt-4">
                        <h4 class="text-center">Schema</h4>
                        <div class="row my-4">
                            <button v-b-modal.modal-buy class="btn btn-border m-auto" type="button">
                                Buy
                            </button>
                        </div>
                        <div class="p-2">
                            <p class="text-center mb-0">What´s included?</p>
                            <b-collapse id="collapse-2">
                                <b-card>
                                    <ul>
                                        <li>LOGGA IN, INBJUDEN, SKAPA NY</li>
                                        <li>ÖPPNA OCH STÄNG GRIND</li>
                                        <li>AUTENSIERINGVIAFACEID</li>
                                        <li>ANTECKNINGVIDÖPPNING</li>
                                        <li>PUSHNOTISER MED VARNINGAR</li>
                                        <li>FÖRETAGSKOPPLINGMOTGRIND</li>
                                    </ul>
                                </b-card>
                            </b-collapse>
                            <button v-b-toggle.collapse-2 variant="primary" class="text-center mb-0 w-100">
                                <div class="icon-box mt-3"><b-icon stacked icon="chevron-down"></b-icon></div>
                            </button>
                        </div>
                    </div>
                </div>
            </b-col>
            <b-col sm="12" lg="6" xl="3">
                <div class="sub-box dmx-gate-card">
                    <span class="price">Price: 5399kr/år</span>
                    <div class="pt-4">
                        <h4 class="text-center">
                            MONITORING & SUPPORT
                        </h4>
                        <div class="row my-4">
                            <button v-b-modal.modal-buy class="btn btn-border m-auto" type="button">
                                Buy
                            </button>
                        </div>
                        <div class="p-2">
                            <p class="text-center mb-0">What´s included?</p>
                            <b-collapse id="collapse-3">
                                <b-card>
                                    <ul>
                                        <li>LOGGA IN, INBJUDEN, SKAPA NY</li>
                                        <li>ÖPPNA OCH STÄNG GRIND</li>
                                        <li>AUTENSIERINGVIAFACEID</li>
                                        <li>ANTECKNINGVIDÖPPNING</li>
                                        <li>PUSHNOTISER MED VARNINGAR</li>
                                        <li>FÖRETAGSKOPPLINGMOTGRIND</li>
                                    </ul>
                                </b-card>
                            </b-collapse>
                            <button v-b-toggle.collapse-3 variant="primary" class="text-center mb-0 w-100">
                                <div class="icon-box mt-3"><b-icon stacked icon="chevron-down"></b-icon></div>
                            </button>
                        </div>
                    </div>
                </div>
            </b-col>
            <b-col sm="12" lg="6" xl="3">
                <div class="sub-box dmx-gate-card">
                    <span class="price">Price: 5799kr/år</span>
                    <div class="pt-4">
                        <h4 class="text-center">
                            Easy access
                        </h4>
                        <div class="row my-4">
                            <button v-b-modal.modal-buy class="btn btn-border m-auto" type="button">
                                Buy
                            </button>
                        </div>
                        <div class="p-2">
                            <p class="text-center mb-0">What´s included?</p>
                            <b-collapse id="collapse-4">
                                <b-card>
                                    <ul>
                                        <li>LOGGA IN, INBJUDEN, SKAPA NY</li>
                                        <li>ÖPPNA OCH STÄNG GRIND</li>
                                        <li>AUTENSIERINGVIAFACEID</li>
                                        <li>ANTECKNINGVIDÖPPNING</li>
                                        <li>PUSHNOTISER MED VARNINGAR</li>
                                        <li>FÖRETAGSKOPPLINGMOTGRIND</li>
                                    </ul>
                                </b-card>
                            </b-collapse>
                            <button v-b-toggle.collapse-4 variant="primary" class="text-center mb-0 w-100">
                                <div class="icon-box mt-3"><b-icon stacked icon="chevron-down"></b-icon></div>
                            </button>
                        </div>
                    </div>
                </div>
            </b-col>
        </b-row>

        <b-row>
            <table class="table b-table table-striped table-hover table-bordered border">
                <thead class="dmx-thead-block">
                    <tr>
                        <th scope="col">#Order number</th>
                        <th scope="col">Period</th>
                        <th scope="col">Total</th>
                        <th scope="col">Status</th>
                        <th scope="col"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th scope="row">123</th>
                        <td>2022-11-01 to 2023-11-01</td>
                        <td>5600kr</td>
                        <td><label class="badge badge-success">Paid</label></td>
                        <td><label class="badge badge-info">Download recipt</label></td>
                    </tr>
                    <tr>
                        <th scope="row">223</th>
                        <td>2022-11-01 to 2023-11-01</td>
                        <td>5600kr</td>
                        <td><label class="badge badge-success">Paid</label></td>
                        <td><label class="badge badge-info">Download recipt</label></td>
                    </tr>
                    <tr>
                        <th scope="row">723</th>
                        <td>2022-11-01 to 2023-11-01</td>
                        <td>5600kr</td>
                        <td><span class="badge badge-secondary">Inactive</span></td>
                        <td><label class="badge badge-info">Download recipt</label></td>
                    </tr>
                    <tr>
                        <th scope="row">723</th>
                        <td>2022-11-01 to 2023-11-01</td>
                        <td>5600kr</td>
                        <td><label class="badge badge-danger">Not paid</label> <span class="badge badge-secondary">Inactive</span></td>
                        <td><label class="badge badge-info">Download recipt</label></td>
                    </tr>
                </tbody>
            </table>
        </b-row>

        <div>
            <b-modal id="modal-buy" title="Upgrade subscription?">
                <p class="my-4">Do you want to upgrade your subscription today? When you choose this package, you will also get all the other add-ons that are on the packages below.</p>
            </b-modal>
        </div>
        <div>
            <b-modal id="modal-downgrade" title="End subscription?">
                <p class="my-4">
                    Are you sure you want to cancel this subscription today? If you choose to remove it, all other add-ons will stop working at the end of the subscription period.
                </p>
            </b-modal>
        </div>
    </div>

</template>

<style scoped>
    .btn-border {
        color: #003440;
        background-color: transparent;
        border-color: #003440;
    }

    .bg-white {
        background-color: #fff;
    }

    a {
        text-decoration: unset
    }

    .sub-box {
        min-height:290px;
        border-radius: 10px;
        border: 2px solid #003440
    }
    .sub-box h4{
        text-transform:uppercase
    }

    .dmx-gate-card {
        background-color: #fff;
        padding: 25px 15px;
    }
    .price {
        position: absolute;
        top: 20px;
        right: 32px;
    }

    .selected {
        background-color: #f3f2e8;
    }

    .icon-box {
        width: 30px;
        height: 30px;
        margin: auto;
        background-color: #003440;
        border-radius: 50%;
        padding: 6px;
    }

        .icon-box .b-icon {
            color: #fff
        }

    .card {
        border: none;
        font-size: 14px;
        background-color:transparent
    }
    .not-collapsed .icon-box .b-icon {
        transform: rotate(180deg);
        margin-bottom: 2px;
    }

    .card ul {
        margin-bottom: 0;
        text-transform:uppercase
    }

</style>

<script>

    export default {
        props: {
            gate: {},
            subscriptionList: [],
        },
        data() {
            return {
                longText: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin accumsan efficitur posuere. Duis eleifend aliquam efficitur. Curabitur porta fermentum est, egestas bibendum lacus tempus quis. Donec nec ex id felis maximus efficitur. Curabitur sit amet turpis quis odio fringilla maximus vitae consectetur erat. Etiam fringilla in sapien a porta. Curabitur sit amet arcu sed turpis bibendum sodales. Maecenas vitae sapien euismod, ultricies quam aliquam, lacinia est. Sed aliquam fermentum risus, vitae hendrerit mauris pulvinar id. Ut lobortis felis non dui sodales, eget aliquet est condimentum. Nullam ornare erat a ipsum bibendum scelerisque. Proin vitae orci condimentum, lobortis nisi vitae, lobortis erat. Quisque ultricies pretium malesuada. Integer non auctor erat. Sed vel facilisis justo.`,
                readMoreActivated: false,
            }

        },
        methods: {
            toggleReadMore() {
                this.readMoreActivated = !this.readMoreActivated;
                this.icon = (this.icon === "check" ? "cgd" : "chevron-down")
            },

        },
        computed: {

        },
        watch: {
            subscriptionList: {
                handler() {
                    this.subscriptionListFormatted = [{ value: null, text: 'Choose subscription model' }];
                    for (let i in this.subscriptionList) {
                        this.subscriptionListFormatted.push({
                            value: this.subscriptionList[i].id,
                            text: this.subscriptionList[i].name
                        });
                    }
                },
                deep: true,
            },
        }
    }
</script>