import { render, staticRenderFns } from "./EditGate.vue?vue&type=template&id=4c913b4f&scoped=true&"
import script from "./EditGate.vue?vue&type=script&lang=js&"
export * from "./EditGate.vue?vue&type=script&lang=js&"
import style0 from "./EditGate.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4c913b4f",
  null
  
)

export default component.exports