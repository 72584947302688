var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',{staticClass:"mb-2",attrs:{"align-v":"center"}},[_c('b-col',[_vm._v(" "+_vm._s(_vm.pagination.totalListCount)+" users found ")]),_c('b-col',[_c('b-input',{attrs:{"placeholder":"Search gate users"},model:{value:(_vm.searchField),callback:function ($$v) {_vm.searchField=$$v},expression:"searchField"}})],1),_c('b-col',{staticClass:"d-flex align-items-end justify-content-end"},[_c('b-button',{staticClass:"mr-1",attrs:{"variant":"primary"},on:{"click":function($event){}}},[_c('b-icon',{staticClass:"mr-2",attrs:{"icon":"plus"}}),_vm._v(" Add users from list ")],1)],1)],1),_c('div',{staticClass:"d-flex align-items-center mb-1"},[(_vm.gateUsersSelected.length)?_c('b-link',{staticClass:"mr-3",staticStyle:{"font-size":".9em"},on:{"click":function($event){return _vm.bulkRemoveUserGateMapping()}}},[_c('span',{staticStyle:{"color":"#c00 !important"}},[_c('b-icon',{attrs:{"icon":"x"}}),_vm._v(" Disconnect selected users")],1)]):_vm._e()],1),(_vm.gateUsersList.length)?_c('div',[_c('b-table',{attrs:{"id":"gate-users-table","thead-class":"dmx-thead-block","outlined":"","striped":"","bordered":"","hover":"","selectable":"","busy":_vm.loading,"items":_vm.gateUsersList,"fields":_vm.headers},on:{"row-selected":_vm.onRowSelected},scopedSlots:_vm._u([{key:"cell(name)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-content-start align-items-center"},[_c('b-form-checkbox',{attrs:{"value":item.userId},model:{value:(_vm.gateUsersSelected),callback:function ($$v) {_vm.gateUsersSelected=$$v},expression:"gateUsersSelected"}}),_vm._v(" "+_vm._s(item.firstName)+" "+_vm._s(item.lastName)+" ")],1)]}},{key:"cell(role)",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('span',{staticClass:"small"},[_c('b',[_vm._v(_vm._s(_vm.getUserGateRoleFormatted(item.userGateRole)))])]),(_vm.hasGatePermission(_vm.permissions.ManageUserPermissions))?_c('b-link',{staticClass:"ml-3 small",on:{"click":function($event){return _vm.editGateUser(index)}}},[_vm._v(" Edit permissions ")]):_vm._e()]}},{key:"cell(notifications)",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('b-link',{staticClass:"ml-3 small",on:{"click":function($event){return _vm.editGateUserNotifications(index)}}},[_vm._v(" Edit notification settings ")])]}}],null,false,1081005084)}),_c('b-pagination',{attrs:{"align":"center","total-rows":_vm.pagination.totalListCount,"per-page":_vm.options.itemsPerPage,"aria-controls":"gate-users-table"},model:{value:(_vm.options.page),callback:function ($$v) {_vm.$set(_vm.options, "page", $$v)},expression:"options.page"}})],1):_c('div',{attrs:{"align":"center"}},[_vm._v("No connected users found, click to add")]),_c('b-modal',{ref:"permission-modal",attrs:{"size":"lg","id":"permission-modal","hide-footer":""},scopedSlots:_vm._u([{key:"modal-title",fn:function(){return [_c('h5',[_vm._v(" Edit gate permissions for user "),_c('b',[_vm._v(" "+_vm._s(_vm.gateUsersList[_vm.gateUserInEdit].firstName)+" "+_vm._s(_vm.gateUsersList[_vm.gateUserInEdit].lastName)+" ")]),_vm._v(" on "),_c('b',[_vm._v(_vm._s(_vm.gate.name))])])]},proxy:true}])},[(_vm.gateUserInEdit !== false)?_c('gate-user-permissions',{attrs:{"gateUser":_vm.gateUsersList[_vm.gateUserInEdit],"gate":_vm.gate}}):_vm._e()],1),_c('b-modal',{ref:"notifications-modal",attrs:{"size":"lg","id":"notifications-modal","hide-footer":""},scopedSlots:_vm._u([{key:"modal-title",fn:function(){return [_c('h5',[_vm._v(" Edit gate notification settings for user "),_c('b',[_vm._v(" "+_vm._s(_vm.gateUsersList[_vm.gateUserInEdit].firstName)+" "+_vm._s(_vm.gateUsersList[_vm.gateUserInEdit].lastName)+" ")]),_vm._v(" on "),_c('b',[_vm._v(_vm._s(_vm.gate.name))])])]},proxy:true}])},[(_vm.gateUserInEdit !== false)?_c('gate-user-notifications',{attrs:{"gateUser":_vm.gateUsersList[_vm.gateUserInEdit],"gate":_vm.gate}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }