<template>
    <div>
        <b-row class="mb-2" align-v="center">
            <b-col>
                {{ pagination.totalListCount }} invites found
            </b-col>
            <b-col>
                <b-input v-model="searchField" placeholder="Search gate invites"></b-input>
            </b-col>
            <b-col class="d-flex align-items-end justify-content-end">
                <b-button variant="success" v-b-modal.invitation-modal>
                    <b-icon icon="plus" class="mr-2"></b-icon> Create invite
                </b-button>
            </b-col>
        </b-row>
        <div v-if="gateInvitesSelected.length" class="d-flex align-items-center mb-1" style="font-size: .9em;">
            <b-link @click="revokeGateInvites()" class="mr-3">
                <span style="color: #c00 !important;"><b-icon icon="x"></b-icon> Revoke selected </span>
            </b-link>
            <b-link @click="renewGateInvites()" class="mr-3">
                <span><b-icon icon="arrow-clockwise"></b-icon> Renew selected </span>
            </b-link>
            <b-link @click="resendGateInvites()" class="mr-3">
                <span><b-icon icon="box-arrow-up-right"></b-icon> Resend selected </span>
            </b-link>
        </div>
        <div v-if="gateInvitesList.length">
            <b-table id="gate-invites-table"
                     thead-class="dmx-thead-block"
                     outlined
                     striped
                     bordered
                     hover
                     selectable
                     @row-selected="onRowSelected"
                     :busy="loading"
                     :items="gateInvitesList"
                     :fields="headers">
                <template #cell(email)="{ item }">
                    <div class="d-flex justify-content-start align-items-center">
                        <b-form-checkbox v-model="gateInvitesSelected" :value="item.id"></b-form-checkbox>
                        {{ item.email }}
                    </div>
                </template>
                <template #cell(createdOnUtc)="{ value }">
                    {{ utcFormatted(value) }}
                </template>
                <template #cell(validToUtc)="{ item, value }">
                    <b-link @click="renewGateInvites(item.id)" title="Click to renew invite">
                        {{ utcFormatted(value) }}
                        <b-icon icon="arrow-clockwise" class="ml-2"></b-icon>
                    </b-link>
                </template>
                <template #cell(sent)="{ item, value }">
                    <b-link @click="resendGateInvites(item.id)" title="Click to resend invitation" class="d-flex justify-content-center align-items-center">
                        <b-icon v-if="value" icon="check" variant="success" font-scale="1.5"></b-icon>
                        <b-icon v-else icon="x" variant="danger" font-scale="1.5"></b-icon>
                        <b-icon icon="box-arrow-up-right" class="ml-2"></b-icon>
                    </b-link>
                </template>
                <!--<template #cell(used)="{ value }">
                    <b-icon v-if="value" icon="check" variant="success" font-scale="1.5"></b-icon>
                    <b-icon v-else icon="x" variant="danger" font-scale="1.5"></b-icon>
                </template>-->
            </b-table>

            <b-pagination align="center" v-model="options.page"
                          :total-rows="pagination.totalListCount"
                          :per-page="options.itemsPerPage"
                          aria-controls="gate-invites-table">
            </b-pagination>
        </div>
        <div v-else align="center">
            <b-link v-b-modal.invitation-modal>No invites found, click to create</b-link>.
        </div>
        <b-modal center size="md" id="invitation-modal" ref="invitation-modal" hide-footer>
            <template #modal-title>
                <h5>Create invitation to <b>{{ gate.name }}</b></h5>
            </template>
            <gate-user-invitation :gate="gate" />
        </b-modal>
    </div>
</template>

<style scoped>
    
</style>

<script>
    import gateInviteService from '@/services/gateInviteService';
    import router from '@/router';
    import moment from 'moment';
    import gateUserInvitation from './gate-user-invitation.vue';

    export default {
        components: {
            'gate-user-invitation': gateUserInvitation,
        },
        props: {
            gate: {},
        },
        data() {
            return {
                loading: true,
                searchField: '',
                searchFieldIsLoading: false,
                searchDebouncer: 0,
                gateInvitesSelected: [],
                gateInvitesList: [],
                gateInviteInEdit: false,
                options: {
                    page: 1,
                    itemsPerPage: 10,
                },
                pagination: {
                    totalListCount: 0
                },
            }
        },
        methods: {
            onRowSelected(e) {
                router.push({ name: 'gateInviteEdit', params: { gateInviteId: e[0].id } })
            },
            getGateInvites() {
                this.loading = true;
                this.gateInvitesSelected = [];
                let reqOb = {
                    Id: this.gate.id,
                    Count: this.options.itemsPerPage,
                    SearchTerm: this.searchField,
                    Page: this.options.page,
                };
           
                gateInviteService.getGateInviteList(reqOb)
                    .then((data) => {
                        //console.log('getGateInvites data', data);
                        this.gateInvitesList = data.items;
                        this.options.page = data.page;
                        this.pagination.totalListCount = data.totalListCount;
                        this.$emit('update-invites-total', this.pagination.totalListCount);
                        this.searchFieldIsLoading = false;
                        this.loading = false;
                    })
                    .catch(function (error) {
                        alert(error);
                    });
            },
            revokeGateInvites() {
                this.$bvModal.msgBoxConfirm('Please confirm to revoke selected invites.', {
                    title: 'Disconnection',
                    okVariant: 'danger',
                    okTitle: 'REVOKE',
                    cancelTitle: 'CANCEL',
                    hideHeaderClose: false,
                    centered: true
                }).then(goahead => {
                    if (goahead) {
                        recRevoke(this.gateInvitesSelected, 0, (err) => {
                            if (err) {
                                this.makeToast('Invite revokation', 'Found errors revoking selected invites.', 'danger');
                            } else {
                                this.makeToast('Invite revokation', 'Successfully revoked selected invites.');
                            }
                            this.getGateInvites();
                        });

                        function recRevoke(ob, i, fn, err=false) {
                            if (i < ob.length) {
                                gateInviteService.deleteGateInvite(ob[i])
                                    .then(data => {
                                        //console.log('recRevoke data', data);
                                        if (!data) err = true;
                                        recRevoke(ob, ++i, fn, err);
                                    });
                            } else fn(err);
                        }
                    }
                })
            },
            renewGateInvites(id = false) {
                let renewals = (id) ? [id] : this.gateInvitesSelected;
                recRenew(renewals, 0, (err) => {
                    if (err) {
                        this.makeToast('Invite renewal', 'Found errors renewing selected invites.', 'danger');
                    } else {
                        this.makeToast('Invite renewal', 'Successfully renewing selected invites.');
                    }
                    this.getGateInvites();
                });

                function recRenew(ob, i, fn, err=false) {
                    if (i < ob.length) {
                        gateInviteService.renewGateInvite({ id: ob[i] })
                            .then(data => {
                                if (!data.success) err = true;
                                recRenew(ob, ++i, fn, err);
                            });
                    } else fn(err);
                }
            },
            resendGateInvites(id = false) {
                let resends = (id) ? [id] : this.gateInvitesSelected;
                recResend(resends, 0, (err) => {
                    if (err) {
                        this.makeToast('Resend invitation', 'Found errors resending selected invites.', 'danger');
                    } else {
                        this.makeToast('Resend invitation', 'Successfully resent selected invites.');
                    }
                    this.getGateInvites();
                });

                function recResend(ob, i, fn, err = false) {
                    if (i < ob.length) {
                        gateInviteService.sendGateInvite(ob[i])
                            .then(data => {
                                if (!data.success) err = true;
                                recResend(ob, ++i, fn, err);
                            });
                    } else fn(err);
                }
            },  
        },
        computed: {
            headers() {
                return [
                    {
                        label: this.$t('common_email'),
                        sortable: true,
                        key: 'email'
                    },
                    {
                        label: this.$t('common_phone'),
                        sortable: true,
                        key: 'phone',
                    },
                    {
                        label: this.$t('common_code'),
                        sortable: true,
                        key: 'code',
                    },
                    {
                        label: this.$t('common_creatededited'),
                        sortable: true,
                        key: 'createdOnUtc'
                    },
                    {
                        label: this.$t('common_creatededitedby'),
                        sortable: true,
                        key: 'createdBy',
                    },
                    {
                        label: 'Valid to',
                        sortable: true,
                        key: 'validToUtc'
                    },
                    {
                        label: this.$t('common_sent'),
                        sortable: true,
                        key: 'sent',
                    },
                    //{
                    //    label: this.$t('common_used'),
                    //    sortable: true,
                    //    key: 'used',
                    //}
                ]
            },
        },
        
        watch: {
            options: {
                handler() {
                    this.getGateInvites()
                },
                deep: true,
                immediate: true,
            },
            searchField: {
                handler() {
                    if (this.searchField.length == 0 || this.searchField.length > 1) {
                        const self = this;
                        clearTimeout(this.searchDebouncer);
                        this.searchDebouncer = setTimeout(() => {
                            self.getGateInvites()
                        }, 400);
                    }
                }
            }
        },
        mounted() {
            this.$root.$on('dmx::sent::gateUserInvitation', () => {
                this.$refs['invitation-modal'].hide();
                this.makeToast('Gate user invitation', `Invitation was successfully sent`);
                this.getGateInvites();
            })
        },
        beforeDestroy() {
            this.$root.$off('dmx::sent::gateUserInvitation');
        }
    }
</script>