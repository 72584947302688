<template>
    <div>
        <b-tabs content-class="mt-3">
            <b-tab active>
                <template #title>
                    Users <b-badge :variant="(numUsers) ? 'primary' : 'light'" class="ml-1">{{ numUsers }}</b-badge>
                </template>
                <gate-users-list v-if="gate.id > 0" :gate="gate" @update-users-total="updateNumUsers" />
            </b-tab>
            <b-tab v-if="hasGatePermission(permissions.ManageUserInvites)">
                <template #title>
                    Invites <b-badge :variant="(numInvites) ? 'primary' : 'light'" class="ml-1">{{ numInvites }}</b-badge>
                </template>
                <gate-invites-list v-if="gate.id > 0" :gate="gate" @update-invites-total="updateNumInvites" />
            </b-tab>
        </b-tabs>
    </div>
</template>

<style scoped>
    
</style>

<script>
    import { mapState } from 'vuex';
    import { UserRoles, Permissions } from '@/variables/variables';
    import gateUsersList from './gate-users-list.vue';
    import gateInvitesList from './gate-invites-list.vue';

    export default {
        components: {
            'gate-users-list': gateUsersList,
            'gate-invites-list': gateInvitesList,
        },
        props: {
            gate: {},
        },
        data() {
            return {
                loading: true,
                numUsers: 0,
                numInvites: 0,
                userRoles: UserRoles,
                permissions: Permissions,
            }
        },
        methods: {
            updateNumUsers(num) {
                this.numUsers = num;
            },
            updateNumInvites(num) {
                this.numInvites = num;
            },
            hasGatePermission(perm) {
                if (this.stateUser.userRole >= this.userRoles.DemexAdmin) return true;
                else {
                    for (let i in this.stateUser.gates) {
                        if (this.stateUser.gates[i].id == this.gate.id) {
                            return this.stateUser.gates[i].userGatePermissions.includes(perm);
                        }
                    }
                    return false;
                }
            },
        },
        computed: {
            ...mapState({
                stateUser: store => store.authentication.user,
            }),
        },
        
        watch: {
            
        },
        mounted() {
            
        }
    }
</script>