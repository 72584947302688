<template>
    <ValidationObserver ref="observer" tag="form" v-slot="{ handleSubmit }" @submit.prevent>

        <b-row no-gutters align-v="center">
            <b-col sm="12">
                <label class="mb-1">E-mail</label><small class="text-danger"><em> *</em></small>
                <ValidationProvider name="E-mail" rules="required|email" v-slot="v">
                    <b-form-input v-model="gateInvite.email" spellcheck="false" type="text"
                        autocomplete="off"></b-form-input>
                    <small class="text-danger"><em>{{ v.errors[0] }}</em></small>
                </ValidationProvider>
            </b-col>
        </b-row>
        <b-row no-gutters align-v="center" class="mt-2">
            <b-col sm="12">
                <label class="mb-1">Phone</label><small class="text-danger"><em> *</em></small>
                <ValidationProvider name="Phone" rules="required" v-slot="v">
                    <b-form-input v-model="gateInvite.phone" spellcheck="false" type="text"
                        autocomplete="off"></b-form-input>
                    <small class="text-danger"><em>{{ v.errors[0] }}</em></small>
                </ValidationProvider>
            </b-col>
        </b-row>
        <b-row no-gutters align-v="center" class="mt-2">
            <b-col sm="12">
                <ValidationProvider name="Role" rules="required_dd" v-slot="v">
                    <label class="mb-1">Role</label><small class="text-danger"><em> *</em></small>
                    <b-select v-model="gateInvite.requestedGateRole" :options="availableGateRoles"></b-select>
                    <small class="text-danger"><em>{{ v.errors[0] }}</em></small>
                </ValidationProvider>
                <span v-if="gateInvite.requestedGateRole === 10">
                    <label class="mb-1 mt-2">Valid to</label>
                    <b-form-datepicker v-model="gateInvite.gateUserValidTo" class="mb-2"></b-form-datepicker>
                </span>
            </b-col>
        </b-row>

        <!--                 Valid until
                <b-form-datepicker v-model="gateInvite.validTo" class="mb-2"></b-form-datepicker> -->

        <b-row align-v="center" class="mt-2">
            <b-col class="d-flex align-items-center justify-content-end">
                <b-button @click="cancel()" variant="primary" class="mr-1">CANCEL</b-button>
                <b-button @click="handleSubmit(sendInvite)" variant="success">SEND</b-button>
            </b-col>
        </b-row>
    </ValidationObserver>
</template>

<style scoped></style>

<script>
import gateService from '@/services/gateService';
import gateInviteService from '@/services/gateInviteService';

export default {
    props: {
        gate: {},
    },
    data() {
        return {
            gateInvite: {
                id: 0,
                email: '',
                phone: '',
                requestedGateRole: 0,
                /* validToUtc: '', */
                gateId: 0,
                gateUserValidTo: null
            },
            availableGateRoles: []
        }
    },
    methods: {
        cancel() {
            this.$root.$emit('bv::hide::modal', 'invitation-modal');
        },
        sendInvite() {
            this.$refs.observer.validate().then(success => {
                this.gateInvite.gateId = this.gate.id;
                gateInviteService.createGateInvite(this.gateInvite)
                    .then(res => {
                        //console.log('createGateInvite data', res.data);
                        if (res.data.success) {
                            this.$root.$emit('dmx::sent::gateUserInvitation');
                        } else {
                            this.errorMessage = res.data.message;
                        }
                    });
            });
        },
        getAvailableGateRoles() {
            this.avaiableGateRoles = [];
            gateService.getAvailableGateRoles()
                .then((data) => {
                    this.availableGateRoles = [{ value: 0, text: 'Choose role' }];
                    for (let key in data) {
                        this.availableGateRoles.push({ value: data[key], text: this.$t('gateRole_' + key) });
                    }
                });
        }
    },
    mounted() {
        this.getAvailableGateRoles();
    }
}
</script>